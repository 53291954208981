<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-card>
        <div slot="header">
          <strong>Добавить статус товара</strong>
        </div>
        <b-row>
          <b-col sm="12">
            <b-form-group>
              <label for="name">Название *</label>
              <b-form-input v-model="$v.form.name.$model" type="text" id="name" placeholder="Введите название"></b-form-input>
              <span class="form-error" v-if="$v.form.name.$dirty && !$v.form.name.required">Название обязательное поле</span>
              <span class="form-error" v-if="$v.form.name.$dirty && !$v.form.name.maxLength">Название не должно быть длиннее {{$v.form.name.$params.maxLength.max}} символов.</span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <b-form-group>
              <label for="text">Текст</label>
              <b-form-textarea v-model="$v.form.text.$model" id="text" rows="3" max-rows="6" type="text" placeholder="Введите текст страницы"></b-form-textarea>
              <span class="form-error" v-if="$v.form.text.$dirty && !$v.form.text.maxLength">Текст не должен быть длиннее {{$v.form.text.$params.maxLength.max}} символов.</span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <b-form-group>
              <label for="icon_class">Класс иконки</label>
              <b-form-input v-model="$v.form.icon_class.$model" type="text" id="icon_class" placeholder="Введите класс с иконкой"></b-form-input>
              <span class="form-error" v-if="$v.form.icon_class.$dirty && !$v.form.icon_class.maxLength">Класс иконки не должен быть длиннее {{$v.form.icon_class.$params.maxLength.max}} символов.</span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <b-form-group>
              <label for="status">Статус *</label>
              <b-form-select v-model="$v.form.status.$model" :options="statuses" id="status"></b-form-select>
              <span class="form-error" v-if="$v.form.status.$dirty && !$v.form.status.required">Статус обязательное поле</span>
              <span class="form-error" v-if="$v.form.status.$dirty && !$v.form.status.maxLength">Неправильный статус.</span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4" offset-md="8" offset-lg="9" lg="3" sm="12">
            <b-button type="submit" block variant="primary"><span class="fa fa-save" /> Сохранить</b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'AddProductStatus',
  data: () => {
    return {
      form: {
        name: '',
        text: '',
        icon_class: '',
        status: null,
      },
      statuses: [
        { value: null, text: 'Выберите статус' },
        { value: 1, text: 'Активный' },
        { value: 0, text: 'Скрытый' },
      ],
    }
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(256)
      },
      text: {
        maxLength: maxLength(2064),
      },
      icon_class: {
        maxLength: maxLength(25),
      },
      status: {
        required,
        maxLength: maxLength(50),
      },
    }
  },
}
</script>
